<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-text-field
          placeholder="Search"
          filled
          dense
          prepend-inner-icon="mdi-magnify"
          v-model="searchStr"
          @keyup.enter.native="fetchStaffs"
          class="float-right"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="staffs"
      :loading="isLoading"
      loading-text="Loading... Please wait"
      class="elevation-1"
      hide-default-footer
      :items-per-page="25"
    >
      <template v-slot:item.is_active="{ item }">
      <v-chip
        :color="getStatusColor(item.is_active)"
        dark
      >
        {{ getStatus(item.is_active) }}
      </v-chip>
    </template>

     <template v-slot:item.role="{ item }">
        <div class="capitalize">{{ item.role }}</div>
    </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Staffs</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <!-- Create New User Dialog Box            -->
          <v-dialog v-model="dialogStaff" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                New Staff
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }} Staff Account</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="8" v-if="editStaffIndex != -1">
                      <v-checkbox
                        v-model="staff.is_active"
                        label="isActive"
                        @change="$v.staff.is_active.$touch()"
                        :error-messages="checkIsActiveErrors"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="12" md="8">
                      <v-text-field
                        v-model="staff.name"
                        label="Name"
                        @change="$v.staff.name.$touch()"
                        :error-messages="checkNameErrors"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="8">
                      <v-text-field
                        v-model="staff.email"
                        label="Email"
                        type="email"
                        @change="$v.staff.email.$touch()"
                        :error-messages="checkEmailErrors"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="8">
                      <v-text-field
                        v-model="staff.password"
                        label="Password"
                        type="password"
                        @change="$v.staff.password.$touch()"
                        :error-messages="checkPasswordErrors"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="8">
                      <v-text-field
                        v-model="staff.password_confirmation"
                        label="Confirm Password"
                        type="password"
                        @change="$v.staff.password_confirmation.$touch()"
                        :error-messages="checkConfirmPasswordErrors"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="8">
                      <v-select
                        v-model="staff.role"
                        :hint="`Assign a role`"
                        :items="roles"
                        item-text="roleName"
                        item-value="roleValue"
                        label="Select"
                        persistent-hint
                        single-line
                        @change="$v.staff.role.$touch()"
                        :error-messages="checkRoleErrors"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="save">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Delete New User Dialog Box, Can enable it later on if needed    -->
          <!-- <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >Are you sure you want to delete this staff
                member?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteStaffConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog> -->
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editStaff(item)">
          mdi-pencil
        </v-icon>
        <!-- <v-icon small @click="deleteStaff(item)">
          mdi-delete
        </v-icon> -->
      </template>
    </v-data-table>
    <br /><br />
    <Pagination
      store="staffs"
      collection="staffs"
      :search-str="searchStr"
    ></Pagination>
  </v-container>
</template>

<script>
import {
  FETCH_STAFFS,
  REGISTER,
  DELETE_STAFF,
  UPDATE_STAFF,
} from "@/store/actions.type";
import { mapGetters } from "vuex";
import { email, required, sameAs, minLength } from "vuelidate/lib/validators";
import Pagination from "@/views/components/Pagination";
export default {
  components: {
    Pagination,
  },
  data: () => ({
    dialogStaff: false,
    dialogDelete: false,
    deleteStaffIndex: null,
    editStaffIndex: -1,
    searchStr: "",
    headers: [
      {
        text: "Id",
        align: "start",
        value: "id",
      },
      { text: "Active", value: "is_active", sortable: false },
      { text: "Name", value: "name", sortable: false },
      { text: "Email", value: "email", sortable: false },
      { text: "Role", value: "role", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    roles: [{ roleName: "System Admin", roleValue: "system admin" }],
    staff: {
      is_active: false,
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
      role: "",
    },
    defaultStaff: {
      is_active: false,
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
      role: "",
    },
  }),
  validations: {
    staff: {
      is_active: { required },
      name: { required },
      email: { required, email },
      password: { required, minLength: minLength(8) },
      password_confirmation: { required, sameAsPassword: sameAs("password") },
      role: { required },
    },
  },
  computed: {
    ...mapGetters("staffs", ["staffs", "isLoading"]),
    formTitle() {
      return this.editStaffIndex === -1 ? "New" : "Edit";
    },
    checkIsActiveErrors(){
      const isActiveErrors = [];
      if (!this.$v.staff.is_active.$dirty) return isActiveErrors;
      !this.$v.staff.is_active.required && isActiveErrors.push("Name is required");
      return isActiveErrors;
    },
    checkNameErrors() {
      const nameErrors = [];
      if (!this.$v.staff.name.$dirty) return nameErrors;
      !this.$v.staff.name && nameErrors.push("Must be valid name");
      !this.$v.staff.name.required && nameErrors.push("Name is required");
      return nameErrors;
    },
    checkEmailErrors() {
      const emailErrors = [];
      if (!this.$v.staff.email.$dirty) return emailErrors;
      !this.$v.staff.email.email && emailErrors.push("Must be valid e-mail");
      !this.$v.staff.email.required && emailErrors.push("E-mail is required");
      return emailErrors;
    },
    checkPasswordErrors() {
      const passwordErrors = [];
      if (!this.$v.staff.password.$dirty) return passwordErrors;
      !this.$v.staff.password.required &&
        passwordErrors.push("Password is required");
      !this.$v.staff.password.minLength &&
        passwordErrors.push("Password length should be greater than 8");
      return passwordErrors;
    },
    checkConfirmPasswordErrors() {
      const confirmPasswordErrors = [];
      if (!this.$v.staff.password_confirmation.$dirty) return confirmPasswordErrors;
      !this.$v.staff.password_confirmation.required &&
        confirmPasswordErrors.push("confirmPassword is required");
      !this.$v.staff.password_confirmation.sameAsPassword &&
        confirmPasswordErrors.push("Must be same as password");
      return confirmPasswordErrors;
    },
    checkRoleErrors() {
      const roleErrors = [];
      if (!this.$v.staff.role.$dirty) return roleErrors;
      !this.$v.staff.role.required && roleErrors.push("role is required");
      return roleErrors;
    },
  },
  watch: {
    dialogStaff(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.fetchStaffs();
  },
  methods: {
    fetchStaffs() {
      this.$store.dispatch(`staffs/${FETCH_STAFFS}`, {
        pageNumber: 1,
        searchStr: this.searchStr,
      });
    },
    getStatusColor(statusCode){
      return statusCode ? "green" : "red";
    },
    getStatus(statusCode){
      return statusCode ? "Yes" : "No";
    },
    close() {
      this.dialogStaff = false;
      this.$nextTick(() => {  
        this.staff = Object.assign({}, this.defaultStaff);
        this.editStaffIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    deleteStaff(staff) {
      this.dialogDelete = true;
      this.deleteStaffIndex = staff.id;
    },
    deleteStaffConfirm() {
      const staffId = this.deleteStaffIndex;
      this.$store.dispatch(DELETE_STAFF, { staffId });
    },
    editStaff(staff) {
      this.dialogStaff = true;
      this.editStaffIndex = staff.id;
      this.staff = Object.assign({}, staff);
    },
    save() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.editStaffIndex === -1) {
          this.$store.dispatch(`staffs/${REGISTER}`, {
            is_active: this.staff.is_active,
            name: this.staff.name,
            email: this.staff.email,
            password: this.staff.password,
            password_confirmation: this.staff.password_confirmation,
            role: this.staff.role,
          }).then(() => {
              this.dialogStaff = false;
              this.fetchStaffs();
          });
        } else {
          this.$store.dispatch(`staffs/${UPDATE_STAFF}`, {
            id: this.staff.id,
            is_active: this.staff.is_active,
            name: this.staff.name,
            email: this.staff.email,
            password: this.staff.password,
            password_confirmation: this.staff.password_confirmation,
            role: this.staff.role,
          }).then(() => {
              this.dialogStaff = false;
              this.fetchStaffs();
          });
        }
      }
    },
  },
};
</script>
<style scoped>
.capitalize {
    text-transform: capitalize;
}
</style>
